.titles{
    -webkit-transition: 1s; /*사파리 & 크롬*/
    -moz-transition: 1s;  /*파이어폭스*/
    -ms-transition: 1s;	/*인터넷 익스플로러*/
    -o-transition: 1s;  /*오페라*/
    transition: 1s;

    margin: 10px 20px 0px 20px;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    text-align:center;
}

.topic-card-team{
    -webkit-transition: 0.5s; /*사파리 & 크롬*/
    -moz-transition: 0.5s;  /*파이어폭스*/
    -ms-transition: 0.5s;	/*인터넷 익스플로러*/
    -o-transition: 0.5s;  /*오페라*/
    transition: 0.5s;
    
    margin: 10px 10px 0px 10px;
    color: black;
    text-align: left;
}

.upperbar{
    -webkit-transition: 0.5s; /*사파리 & 크롬*/
    -moz-transition: 0.5s;  /*파이어폭스*/
    -ms-transition: 0.5s;	/*인터넷 익스플로러*/
    -o-transition: 0.5s;  /*오페라*/
    transition: 0.5s;

    margin: 10px 0px 0px 45px;
    height: 5px;
    width: 100px;
    vertical-align: middle;
    border-radius: 10px;
    background-color:black;
}

.topic-card:hover .upperbar{
    margin: 20px 0px 0px 20px;
    width: 150px;
    height: 10px;
    background-color: white;
}

.topic-card:hover .titles{
    margin-top: 30px;
    color: white;
}

.topic-card:hover .topic-card-team{
    color: white;
}