.topic-page-sub-header {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.main-material {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.main-material-docs {
  width: 80%;
  height: 50rem;
  border-radius: 5px;
  border: 2px solid grey;
  padding: 0.1em;
}