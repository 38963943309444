.home-page-content {
  text-align: left;
  padding: 1rem;
}

.home-page-sub-header {
  margin-top: 2rem;
  padding: 1rem;
}

.home-page-header {
  margin-top: 3rem;
  padding: 1rem;
}

iframe {
  margin-bottom: 1.5rem;
}