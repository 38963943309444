body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.admin-page {
  text-align: center;
}

.admin-page-manage-buttons {
  text-align: left;
  padding: 30px;
}

.btn {
  margin-left: 5px;
  margin-right: 5px;
}

.btn-sm {
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 3px;
  margin-bottom: 3px;
}

#promo{
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.4)), url(./assets/img/NutshellImage.jpg);
  background-size: cover;
  background-position: center;
  color:white;
  background-attachment: fixed;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 48px;
}

.topic-card{
  -webkit-transition: 1s; /*사파리 & 크롬*/
  -moz-transition: 1s;  /*파이어폭스*/
  -ms-transition: 1s;	/*인터넷 익스플로러*/
  -o-transition: 1s;  /*오페라*/
  transition: 1s;
  
  height: 200px;
	width: 190px;
	display: inline-block;
	margin-top: 20px;
	margin-bottom: 10px;
	position: relative;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19);
	overflow: hidden;
}

.topic-page #topic-title {
  text-align: center;
  padding: 30px;
}

.topic-page #topic-team {
  text-align: right;
}

.topic-page #topic-page-loading {
  font-size: 30px;
  padding: 20px;
  text-align: center;
}

.topic-page-header {
  margin-top: 40px;
}

.board #board-loading {
  font-size: 30px;
  padding: 20px;
  text-align: center;
}

.home-page-stationary-supplies {
  text-align: center;
}


.topic-card:hover {
  background-image: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)), url(./assets/img/NutshellImage.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
}

.loading {
  margin-top: 50px;
  margin-bottom: 50px;
}